import React from 'react';
import PropTypes from 'prop-types';

import Button from 'pubweb-react-components/dist/components/Buttons/Button';
import WindowSizeComponent from 'pubweb-react-components/dist/components/WindowSize/WindowSizeComponent';
import InputField from 'pubweb-react-components/dist/components/Forms/InputField';
import CheckBox from 'pubweb-react-components/dist/components/Forms/Checkbox/Checkbox';
import Spinner from 'pubweb-react-components/dist/components/Icons/Spinner';

import TextField from 'pubweb-smokey/dist/components/TextField/TextField';
import SmokeyButton from 'pubweb-smokey/dist/components/Buttons/Button';

import variables from '../variables';

import { trackGAEvent } from '../../utils/analytics';
import Form from 'pubweb-react-components/dist/components/Forms/Form';
import PrefabFormStyles from './PrefabForm.styled';
import { runCommonValidation } from 'pubweb-react-components/dist/utils/validation';
import { submitForm } from '../../services/formService';


const customMessages = {
    firstNameMissing: "First name is required.",
    lastNameMissing: "Last name is required.",
    emailMissing: "Email address is required.",
    zipMissing: "Zip code is required.",
    phoneMissing: "Phone number is required."
};

class PrefabForm extends WindowSizeComponent {
    constructor(props){
        super(props);

        this.state = {
            formData: {},
            formErrors: {},
            formSubmitError: false,
            showExtended: false,
            submittingForm: false,
            windowWidth: window ? (window.innerWidth || window.screen.availWidth ) : variables.desktop_breakpoint_width
        };

        this.handleValidation = this.handleValidation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    handleFormChange(formData){
        this.setState({
            formData: {...this.state.formData,
                       ...formData}
        });
    }

    handleValidation(formState){
        const formErrors = runCommonValidation(formState, customMessages);

        this.setState({
            formErrors: formErrors
        });

        return formErrors.hasErrors;
    }

    handleSubmit(formData){
        this.setState({
            submittingForm: true,
            formSubmitError: false
        });

        debugger;

        console.log(this.props.haveLand);
        const land = (this.props.haveLand && this.props.haveLand === "yes") ? "Has Land" : "Needs Land";
        const budget = this.props.budget;
        let priceRange = budget;

        if (budget !== null){
            switch(priceRange)
            {
                case "$25,000-$50,000":
                    priceRange = "<50K";
                    break;
                case "$50,000-$75,000":
                    priceRange = "65K-75K";
                    break;
                case "$75,000-$150,000":
                    priceRange = "125K-150K";
                    break;
                default:
                    priceRange = ">300K";
                    break;
            }
        }
             
        const buyingDatePlan = this.props.buyingDatePlan;
        let monthsAhead = 0;

        switch(buyingDatePlan){
            case "0-3 months":
                monthsAhead = 3;
                break;
            case "3-6 months":
                monthsAhead = 6;
                break;
            case "6-9 months":
                monthsAhead = 9;
                break;
            case "9-12 months":
                monthsAhead = 12;
                break;
        }

        const buyingDate =  new Date();
        buyingDate.setMonth(buyingDate.getMonth() + monthsAhead);
        const planToBuyMonth = buyingDate.toLocaleString("default", { month: "long" })
        const planToBuyYear = buyingDate.getFullYear();

        const formSubmission = {
            ...formData,
            canEmail: true,
            dataExtension: 'FormActivityPrefab',
            originWebFormType: (formData.isLead || this.props.isQuizForm) ? 'lead-form' : 'marketing-form',
            originWebApp: 'clayton-homes-website',
            originWebPageType: this.props.isQuizForm ? 'quiz-landing-page' : 'landing-page',
            originCampaign: 'prefab-2019',
            originWebFormCategory: this.props.category.toLowerCase(),
            ...(this.props.isQuizForm && {priceRange: priceRange}),
            ...(this.props.isQuizForm && {land: land}),
            ...(this.props.isQuizForm && monthsAhead > 0 && {planToBuyMonth: planToBuyMonth}),
            ...(this.props.isQuizForm && monthsAhead > 0 && {planToBuyYear: planToBuyYear}),
        };

        submitForm(formSubmission).then(() => {
            
            trackGAEvent("form-submit-" + this.props.category.toLowerCase()).then(() => {
                if(this.props.isQuizForm){
                    window.location.href = this.props.quizRedirectUrl;
                }
                else if(this.state.showExtended) {
                    window.location.href = this.props.leadRedirectUrl; ;
                }
                else {
                    window.location.href = this.props.marketingRedirectUrl;
                }
            });
        }).catch(error => {
            this.setState({
                submittingForm: false,
                formSubmitError: true
            });
        });
    }

    render(){

        const formErrors = this.state.formErrors;

        return <PrefabFormStyles className={this.props.className}>
            {this.state.formSubmitError ? <span className="form-error">An error occurred while submitting the form. Please try again.</span> : null}
            <Form onFormChange={this.handleFormChange} onFormValidate={this.handleValidation} onSubmit={this.handleSubmit}>
                { this.props.isQuizForm ?
                    <div className="field-group">
                        <div className="field-column">
                            <TextField 
                                label="First name"
                                id="firstName" 
                                name="firstName" 
                                automationId="firstName" 
                                className="smokey-textfield" 
                                errorMessage={formErrors.firstName} 
                                defaultValue={this.state.formData.firstName} 
                            />
                        </div>
                        <div className="field-column">
                            <TextField 
                                label="Last name"
                                id="lastName" 
                                name="lastName" 
                                automationId="lastName" 
                                className="smokey-textfield" 
                                errorMessage={formErrors.lastName} 
                                defaultValue={this.state.formData.lastName} 
                            />
                        </div>
                    </div>
                : null }
                <div className="field-row">
                    {!this.props.isQuizForm ?
                        <InputField fillSpace={true} inputName={this.state.showExtended ? 'Email address*' : 'Email*'} inputId="email" error={formErrors.email} errorMsg={formErrors.email} />
                    :
                        <TextField 
                            label="Email address"
                            id="email" 
                            name="email" 
                            automationId="email" 
                            className="smokey-textfield" 
                            errorMessage={formErrors.email} 
                            defaultValue={this.state.formData.email} 
                        />
                    }
                </div>
                {!this.props.isQuizForm ? 
                <div className="field-row prefab-checkbox-container">
                    <CheckBox
                        onChange={(ev) => {
                            this.setState({
                                showExtended: ev.target.checked
                            });
                        }}
                        className={`gtm-checkbox-` + this.props.category.toLowerCase()}
                        size={(this.state.windowWidth <  variables.desktop_breakpoint_width) ? "medium" : "small"}
                        checkboxText="I'd like to be contacted by a home consultant about touring homes."
                        inputId="isLead" />

                </div> : null }
                { this.state.showExtended ?
                    <div className="field-group">
                        <div className="field-column">
                            <InputField initialValue={this.state.formData.firstName} fillSpace={true} inputName="First Name*" inputId="firstName" error={formErrors.firstName} errorMsg={formErrors.firstName} />
                        </div>
                        <div className="field-column">
                            <InputField initialValue={this.state.formData.lastName} fillSpace={true} inputName="Last Name*" inputId="lastName" error={formErrors.lastName} errorMsg={formErrors.lastName} />
                        </div>
                        <div className="field-column">
                            <InputField initialValue={this.state.formData.zip} fillSpace={true} inputName="Zip Code*" inputId="zip" error={formErrors.zip} errorMsg={formErrors.zip} />
                        </div>   
                        <div className="field-column">
                            <InputField initialValue={this.state.formData.phone} fillSpace={true} inputName="Phone Number*" inputId="phone" error={formErrors.phone} errorMsg={formErrors.phone} />
                        </div>

                    </div>
                : null }
                { this.props.isQuizForm ?
                    <div className="field-group">
                        <div className="field-row">
                            <TextField 
                                label="Phone number"
                                id="phone" 
                                name="phone" 
                                automationId="phone" 
                                className="smokey-textfield" 
                                errorMessage={formErrors.phone} 
                                defaultValue={this.state.formData.phone} 
                            />
                        </div>
                        <div className="field-row">
                            <TextField 
                                label="Zip code"
                                id="zip" 
                                name="zip" 
                                automationId="zip" 
                                className="smokey-textfield" 
                                errorMessage={formErrors.zip} 
                                defaultValue={this.state.formData.zip} 
                            />
                        </div>   
                    </div>
                : null }
                {!this.props.isQuizForm ?
                    <Button
                        size="medium"
                        fillSpace={true}
                        name="beep"
                        id={this.props.category + "_submit"}
                        key={this.props.category + "_submit"}
                        disabled={this.state.submittingForm}
                        backgroundColor={this.props.buttonColor}
                        backgroundColorHover={this.props.buttonHoverColor}
                    >
                        {this.state.submittingForm ? <Spinner color="#FFFFFF" /> : this.props.submitButtonText || 'Unlock Content' }
                    </Button>
                :
                    <SmokeyButton
                        name="beep"
                        id={this.props.category + "_submit"}
                        key={this.props.category + "_submit"}
                        disabled={this.state.submittingForm}
                        automationId="See My Results Button"
                    >
                        {this.state.submittingForm ? <Spinner color="#FFFFFF" /> : this.props.submitButtonText || 'Unlock Content' }
                    </SmokeyButton>
                }
            </Form> 
        </PrefabFormStyles>;
    }
}

PrefabForm.props = {
    category: PropTypes.string.isRequired,
    leadRedirectUrl: PropTypes.string.isRequired,
    marketingRedirectUrl: PropTypes.string.isRequired,
    buttonColor: PropTypes.string.isRequired,
    buttonHoverColor: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default PrefabForm;