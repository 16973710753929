import styled from 'styled-components';
import colors from 'pubweb-react-components/dist/styles/colors';

import variables from '../variables';

const PrefabFormStyles = styled.div`
    font-size: 14px;
    
    form{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .field-row {
            width: 100%;
        }
        .field-column {
            width: 50%;
        }
        .field-row, .field-column {
            margin-bottom: 8px;
            padding: 5px 0;
        }
        .field-group .field-column:nth-child(odd) {
            padding-right: 5px;
        }
        .field-group .field-column:nth-child(even) {
            padding-left: 5px;
        }
        button {
            height: 55px;
            margin-top: 24px;
            padding-top: 5px;
        }
    }
    .field-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    label {
        font-size: 16px;
        margin-bottom: 5px;
    }
    .prefab-checkbox-container {
        margin-bottom: 18px;
        & > div {
            padding-top: 16px;
        }
    }
    .prefab-checkbox[type=checkbox] + label:before {
        top: 5px;
    }
    .prefab-checkbox + label {
        font-family: "acumin-pro", sans-serif;
        font-size: 18px;
    }
    .form-error{
        color: ${colors.secondaryLadyBug};
        font-size: 12px;
        height: 42px;
        line-height: 1.56;
        letter-spacing: -0.3px;
        margin: 0;
        text-align: left;
    }
    p.sms-disclaimer{
        margin-top: -32px;
        margin-left: 32px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
    }

    @media screen and (min-width: ${variables.tablet_breakpoint_width+1}px) {
        width: 390px;
    }

    @media screen and (max-width: ${variables.tablet_breakpoint_width}px) {

        form .field-group .field-column:nth-child(odd) {
            padding-right: 0;
        }

        form .field-group .field-column:nth-child(even) {
            padding-left: 0;
        }

        form .field-column {
            width: 100%;
        }
    }
`;

export default PrefabFormStyles;