import {
    DEV_WEB_SERVICE_URL
} from '../utils/constants'

let settings = null;

const appSettingsURL = process.env.APP_SETTINGS_URL;

export const getSettings = () => {
    return new Promise((resolve, reject) => {
        if (settings) {
            resolve(settings);
        } else {
            fetch(appSettingsURL, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())
                .then(json => {
                    json.webServiceUrl = json.webServiceUrl === "#{WebServiceUrl}" ? DEV_WEB_SERVICE_URL : json.webServiceUrl;

                    settings = json;
                    resolve(json);
                })
                .catch(err => reject(err));
        }
    });
}

export const ccpaScriptSource = process.env.CCPA_SCRIPT_SOURCE;

export default getSettings;