import { getSafeUrl } from 'pubweb-react-components/dist/utils/urlUtility';
import { getClickIds, getUTMParametersFromSessionCache } from "pubweb-smokey/dist/utils/utils";
import { getGAClientId, getKenshooId } from '../utils/analytics'
import { LEAD_API_ENDPOINT } from '../utils/constants';
import getSettings from './settingService';

import "isomorphic-fetch";
import promise from 'es6-promise';
promise.polyfill();

export const submitForm = formObj => {
    return getSettings()
        .then(settings => {
            const webUrl = getSafeUrl();
            const utmParams = getUTMParametersFromSessionCache();
            const gaClientId = getGAClientId();
            const kenshooId = getKenshooId();
            const clickIds = getClickIds();
            const formModel = {
                emailAddress: formObj.email ? formObj.email.trim() : undefined,
                firstName: formObj.firstName ? formObj.firstName.trim() : undefined,
                lastName: formObj.lastName ? formObj.lastName.trim() : undefined,
                zip: formObj.zip ? formObj.zip.trim() : undefined,
                phoneNumber: formObj.phone ? formObj.phone.trim() : undefined,
                canEmail: formObj.canEmail,
                dataExtension: formObj.dataExtension,
                doNotCall: formObj.doNotCall,
                priceRange: formObj.priceRange,
                land: formObj.land,
                planToBuyMonth: formObj.planToBuyMonth,
                planToBuyYear: formObj.planToBuyYear,
                googleClickId: clickIds.GoogleClickId,
                bingClickId: clickIds.BingClickId,
                facebookClickId: clickIds.FacebookClickId,
                originWebURL: webUrl,
                originWebApp: formObj.originWebApp,
                originWebPageType: formObj.originWebPageType,
                originWebFormType: formObj.originWebFormType,
                originWebFormCategory: formObj.originWebFormCategory,
                originCampaign: formObj.originCampaign,
                utmCampaign: utmParams.utm_campaign,
                utmSource: utmParams.utm_source,
                utmMedium: utmParams.utm_medium,
                utmTerm: utmParams.utm_term,
                utmContent: utmParams.utm_content,
                googleClickId: utmParams.gclid,
                bingClickId: utmParams.msclkid,
                facebookClickId: utmParams.fbclid,
                gaClientId: gaClientId,
                kenshooId: kenshooId,
            }; 

            return postData(`${settings.webServiceUrl}/api/${LEAD_API_ENDPOINT}`, formModel);
        })
};

export default submitForm;

const postData = (url, data) => {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).catch(err => { 
        console.log(err);
        throw err; 
    });
};
